@keyframes advanced-search-active-blink {
  0% { opacity: 0.7; }
  50% { opacity: 0.3; }
  100% { opacity: 0.7; }
}

.search-control .form-group a {
  color: #fff;
  opacity: 0.5;
  pointer-events: all;
}

.search-control .form-group a.simple-search-active {
  color: #3c8dbc;
}

.search-control .form-group a.dropdown-active,
.search-control .form-group a:hover {
  opacity: 1;
}

.search-control .form-group a.blink {
  animation-name: advanced-search-active-blink;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.search-control .form-group a.dropdown-active.blink,
.search-control .form-group a.blink:hover {
  animation: none;
}




.advanced-search {
  margin-top: 8px;
  padding: 15px;
  width: 500px;
  border-radius: 0;
  z-index: 1025;
}

@media (max-width: 768px) {
  .advanced-search {
    margin-left: 0;
    margin-top: 0;
  }

  .navbar-default .navbar-form {
    border: none;
    text-align: right;
  }
}

.btn-aligned-text {
  padding: 7px 0;
  display: inline-block;
}




.advanced-search hr {
  margin-top: 10px;
  margin-bottom: 15px;
}

.advanced-search .btn-link {
  color: #777;
}

.advanced-search .btn-link:hover,
.advanced-search .btn-link:focus {
  color: #333;
}

.advanced-search .btn-link img {
  opacity: 0.55;
}

.advanced-search .btn-link:hover img,
.advanced-search .btn-link:focus img {
  opacity: 0.8;
}



.advanced-search .criteria-entry {
  margin-bottom: 15px;
}

.advanced-search .criteria-entry header {
  margin-bottom: 5px;
}

.advanced-search .criteria-entry header span {
  margin-top: 5px;
}

.advanced-search .criteria-entry .form-control {
  width: 100%;
}

.advanced-search .criteria-entry .ui-select-dropdown {
  /*position: relative*/;
  margin-left: -3px;
}

.advanced-search .criteria-entry .datapicker-expanded {
  /*height: 278px;*/
}

.advanced-search .criteria-entry .margin-bottom {
  margin-bottom: 10px;
}

.advanced-search .checkbox-inline {
  margin: 5px 0;
  font-size: 14px;
}

.advanced-search .checkbox input{}

@media (max-width: 768px) {
  .advanced-search .margin-bottom-separator {
    margin-bottom: 10px;
  }
}
.advanced-search .criteria-entry.criteria-entry-without_interview header {
  margin-bottom: 0;
}




