.reports .info-box .progress {
  background-color: #f5f5f5;
}

.reports .info-box .progress .progress-bar.progress-bar-primary {
  background-color: #3c8dbc;
}

.reports .info-box .progress .progress-bar.progress-bar-aqua {
  background-color: #00c0ef;
}

.reports .table {
  background-color: #fff;
}

.reports a:hover {
  color: #005c90;
}

.reports th.sortable {
  background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIUnC2nKLnT4or00PvyrQwrPzUZshQAOw==) no-repeat !important;
  background-position: right 5px center;
  padding-right: 15px;
  cursor: pointer;
}

.reports th.sortable.center {
  text-align: center;
  vertical-align: middle;
}

.center {
  text-align: center;
  vertical-align: middle !important;
}

.center.bold {
  font-weight: bold;
}

.reports th.sortable.st-sort-ascent {
  background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIRnC2nKLnT4or00Puy3rx7VQAAOw==) no-repeat !important;
  background-position: right 5px center;
}

.reports th.sortable.st-sort-descent {
  background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=) no-repeat !important;
  background-position: right 5px center;
}

.reports table tbody tr td.bg-white {
  background-color: #fff;
}
