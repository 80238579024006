.vacancies_tabs .nav-tabs > li > a {
  padding-left: 3px;
  padding-right: 3px;
}

.line_bottom + .line_bottom {
  border-top: 1px solid #DEDEDE;
}

.title_vacancy {
  font-size: 18px;
}

.vacancies_list .links {
  margin-bottom: 5px;
  margin-top: 5px;
}

.vacancies_create .content,
.vacancies_edit .content {
  background-color: #ffffff;
}

.vacancy_textarea {
  resize: none
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: left;
  }
}

.title_vacancy:hover {
  cursor: pointer;
}

.create_button {
  margin: 5px 5px 0 0;
}

.history {
  margin-top: 15px;
}

.title {
  font-size: 15px;
  height: 32px;
  padding-top: 6px !important;
}

.textarea_vacancy {
  background-color: transparent;
  border: none;
  resize: none;
  width: 100%;
}

.more_info {
  margin-left: 20px;
  margin-top: 15px;
}

.not_active {
  background-color: #f5f5f5;
}

.vacancies_list .row {
  margin-right: 0px;
  margin-left: 0px;
}

.vacancies_edit .input-group,
.vacancies_create .input-group {
  float: left !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.err_message {
    margin-top: -15px;
    margin-bottom: 10px;
}
