.navbar-form {
    box-shadow: none;
}

.navbar-form .search-results {
    margin-left: 15px;
    color: #fff;
}

.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:hover {
    color: #fff;
}

.skin-blue .main-header .navbar .nav > li:not(.active) > a:focus:not(:hover) {
  background: none;
}

html, body, body > .ui-view {
    height: 100%;
}

.content-wrapper > .container {
    padding-top: 50px;
}

.ui-view .wrapper {
    background-color: #ecf0f5;
    padding-bottom: 50px;
}

.main-footer {
    height: 50px;
    width: 100%;
    margin-top: -50px;
    bottom: 0;
    position: absolute;
}


.table-striped > thead > tr {
    background-color: #ECF0F1;
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: inherit;
}

.table-striped > tbody > tr:nth-of-type(even) {
    background-color: #ECF0F1;
}


.table-bordered,
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > td {
    border-color: #ddd;
}

.table-bordered > thead > tr > th {
    border-bottom-color: #000;
}


.table-hover > tbody > tr:hover {
    background-color: #BDC3C7;
}


.back-to-top {
    z-index: 90000;
    cursor: pointer;
    position: fixed;
    bottom: 60px;
    right: 20px;
    display:none;
    color: #ffffff!important;
    border-radius: 50%;
    width: 46px;
    padding-left: 10px;
    padding-right: 10px;
}

.icon_back_to_top {
    top: 2px;
    right: -0.5px;
}


.modal-body h3:first-child {
    margin-top: 0px;
}


.ui-select-container {
  min-height: 34px;
}


.content .nav-tabs-custom {
  box-shadow: none;
}

.row .col-small-gap {
  padding: 0 5px;
}

.row .col-small-gap:first-child {
  padding-left: 15px;
}

.row .col-small-gap:last-child {
  padding-right: 15px;
}

.button-padding {
  padding-top: 7px;
  padding-bottom: 7px;
}

.dropdown .dropdown-menu {
  box-shadow: 1px 1px 1px 1px rgba(85, 85, 85, 0.3);
  border-radius: 2px;
}

.navbar-collapse.collapse.in {
  box-shadow: 0px 1px 2px 2px rgba(85, 85, 85, 0.3);
}

.skin-blue .navbar-default .navbar-toggle:focus,
.skin-blue .navbar-default .navbar-toggle:hover {
  background-color: #367fa9;
}




.uib-datepicker-popup.dropdown-menu {
  left: 0 !important;
  padding: 5px;
}
