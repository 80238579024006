.applicants_list .table th:nth-child(1) {
  width: 20%;
}

.applicants_list .table th:nth-child(2) {
  width: 160px;
}

.applicants_list .table th:nth-child(3),
.applicants_list .table th:nth-child(4) {
  width: 8.33333333%;
}

.applicants_list .table th:nth-child(5) {
  width: 14%;
}

.applicants_list .table th:nth-child(6) {
  width: 30%;
}

@media (min-width: 768px) {
  /* No changes for column width */
}

@media (min-width: 992px) {
  .applicants_list .table th:nth-child(1) {
    width: 40%;
  }

  .applicants_list .table th:nth-child(6) {
    width: 20%;
  }
}

@media (min-width: 1200px) {
  .applicants_list .table th:nth-child(1) {
    width: 35%;
  }

  .applicants_list .table th:nth-child(3) {
    width: 15%;
  }

  .applicants_list .table th:nth-child(4) {
    width: 10%;
  }

  .applicants_list .table th:nth-child(6) {
    width: 20%;
  }
}




.applicants_list .content {
    padding: 15px 0;
}

.applicants_list .table {
    background-color: #fff;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.applicants_list .table,
.applicants_list .table tbody tr,
.applicants_list .table tbody td {
    /*
     * These styles are added in order to make it possible for inner links to
     * fill 100% width and height of enclosing table cell element
     */
    height: 100%;
}

.applicants_list .table.fixed {
    /**
     * Turn off above styles for a table which is used to make fixed header
     */
    height: 1px;
}

.applicants_list .table td {
    /**
     * Paddings are handled by link elements
     */
    padding: 0;
}

.applicants_list .table td > a {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    font-size: 15px;
    word-break: break-word;
    min-height: 75px;
}

.applicants_list .table td > a > div {
    padding: 10px 15px;
}

.applicants_list .table td:nth-child(1) > a > div {
    padding: 15px 15px 30px;
}

.applicants_list .table td:nth-child(5) > a > div {
    padding-bottom: 10px;
}

.applicants_list .table td:nth-child(4) > a,
.applicants_list .table td:nth-child(3) > a {
    word-break: break-word;
}

.applicants_list .table td:nth-child(6) > a {
  word-break: normal;
}

.applicants_list .table > thead > tr > th {
    height: 40px;
    padding: 5px 15px;
    font-size: 18px;
    text-align: center;
}

.applicants_list a {
    color: #111;
}

.applicants_list .image {
    display: none;
    position: relative;
    float: left;
    width: 70px;
    height: 90px;
}

.applicants_list .photo {
    width: 70px;
    height: 90px;
    border-radius: 25%;
    display: inline-block;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;

}

.applicants_list .tag {
    position: absolute;
    width: 37px;
    right: -10px;
    bottom: -10px;
}

.applicants_list .info div {
    margin-bottom: 5px;
}

.applicants_list .info .fio {
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
}

.applicants_list .info .contacts {
    word-break: break-all;
}

.applicants_list .info .contacts a {
    color: #777;
    font-size: 13px;
}

.applicants_list .info .user {
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
    font-size: 13px;
    color: #2278b7;
}

.applicants_list .contacts span {
    display: block;
    white-space: nowrap;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.applicants_list .interview-mark {
    display: block;
}

.applicants_list td:nth-child(6) > a {
    text-align: center;
}

@media(max-width: 1200px) {
  .applicants_list td:nth-child(6) > a > div {
    padding: 5px;
  }
}

.applicants_list td:last-child a > div {
    text-align: center;
    padding: 15px 5px;
}

@media (min-width: 992px) {
    .applicants_list .table td > a {
        min-height: 120px;
    }

    .applicants_list .image {
        display: block;
    }

    .applicants_list .info {
        margin-left: 90px;
    }
}

@media (min-width: 1200px) {
    .applicants_list .info .user {
        font-size: 15px;
    }

    .applicants_list .info .user span {
        margin-right: 20px;
    }
}

.applicants_list .fixed {
    top: 50px;
    position: fixed;
    width: auto;
    display: none;
    border: none;
    z-index: 1;
}
.applicants_list .col-lg-1, .applicants_list .col-md-1, .applicants_list .col-sm-1, .applicants_list .col-xs-1 {
  width: 0;
}

.applicants_list section.content table.table.table-striped.table-hover.table-bordered tbody tr td a div.status-field-header {
    padding-bottom: 0;
    padding-top: 5px;
}

.applicants_list section.content table.table.table-striped.table-hover.table-bordered tbody tr td a div.status-field-text {
    padding: 5px 10px;
    text-align: left;
    line-height: 1;
    word-spacing: 2px;
    color: #2278b7;
    word-break: break-word;
}
