.applicants_create .content,
.applicants_edit .content {
  border: 1px solid #ddd;
}
.img-avatar {
  height: 344px;
  max-height: 389px;
  max-width: 344px;
  display: block;
  margin: 0 auto;
}
/*.applicant-padding:not(:last-child){*/
  /*padding-right: 0px;*/
/*}*/
.applicant-padding>div:first-child {
  padding-right: 0px;
}
.header_select{
  padding-top: 0px;
}
.buttons{
  width: 100%;
}
.tag-image{
  width: 22px;
  height: 22px;
}
.notvalid{
    color:red;
}
.applicants_create .content,
.applicants_edit .content {
    background-color: #ffffff;
}
.applicants_create .duplicates,
.applicants_edit .content {
    margin-top: 55px;
}
.applicants_create .duplicates .alert {
    border-radius: 0;
}

.applicants_create #applicantForm .ui-select-multiple > div,
.applicants_edit #applicantForm .ui-select-multiple > div {
    padding-right: 20px;
}

.applicants_create #applicantForm .ui-select-multiple.populated input,
.applicants_edit #applicantForm .ui-select-multiple.populated input {
  padding-left: 6px !important;
  padding-right: 0;
  width: 7px !important;
}

.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    background-color: transparent !important;
    border: none;
    outline: none;
    height: 26px;
    margin-bottom: 3px;
}
.upload-button {
    background-color: #99BDDB;
}
.styled-elements{
    margin-bottom: 10px;
}
.copy_marks {
  margin-bottom: 10px;
}
.applicant-textarea {
  resize: none
}
.skills_text{
    word-wrap: break-word;
    white-space: pre-wrap;
}
.note_block{
    word-wrap: break-word;
    width: 62%;
    vertical-align: top;
}
.common_block{
    overflow: hidden;
    min-height: 60px;
}
.border_bottom + .border_bottom{
    border-top: 1px solid #DEDEDE;
}
.interactions_block{
    display: inline-block;
    vertical-align: top;
}
.buttons_block{
    width: 3%;
    vertical-align: top;
    text-align: center;
}
@media(max-width: 992px){
    .sm_buttons_block{
        width: 5%;
        vertical-align: top;
        text-align: center;
    }
}
.date_block{
    width: 10%;
    vertical-align: top;
}
@media(max-width: 992px) {
    .sm_date_block {
        width: 40% ;
        vertical-align: top;
    }
}
@media(max-width: 992px) {
    .sm_type_block {
        width: 48% !important;
        vertical-align: top;
    }
}
.applicants_edit .title {
    font-size: 14px;
    height: 22px;
    padding-top: 0px!important;
}
.type_block{
    width: 20%;
    vertical-align: top;
}
.collapsible-item .body .content {
    min-height: 25px;
    padding: 10px 15px 5px 15px !important;
    margin-top: 0;
}

legend{
    margin-bottom: 0;
    border-color: transparent;
    text-align: center;
    font-size: 18px;
}
fieldset {
    padding: .35em .625em .75em;
    margin: 0 2px;
    border: 1px solid silver;
}
fieldset .button_group {
    padding-left: 5px;
    padding-right: 5px;
}
label{
  font-size: larger;
}
.tad-container{
  padding-top: 20px;
}
#applicantForm .row{
    margin: 0;
}
.interaction_button{
    margin-left: 15px!important;
}
@media(max-width: 992px){
    .button_group {
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 5px;
}
}
.changes {
    word-wrap: break-word;
}
.nav-tabs>li>a {
    position: relative;
    display: block;
    padding: 10px 13px;
}
.nav-tabs-custom>.nav-tabs {
  margin-top: 10px;
}
.error{
    color: #B13B3B;
}
.hasError {
  border-color: red;
}
@media(max-width: 1200px){
  .lg-icons{
  font-size: 2em;
  vertical-align: -15%;
}
.lg-icon {
  width: 30px!important;
  height: 30px!important;
}
  .hide-text{
      display: none;
  }

}

.applicant-cv-view iframe {
    width: 100%;
    height: 880px;
    border-width: 1px;
}

.creator {
    /*text-align: center;*/
    padding: 7px 0;
    margin: 0;
}

input[type=radio]:hover {
    cursor: pointer;
}
.interview-field-wrapper > div:nth-child(2) {
    padding-top: 34px;
}

.interview-field-wrapper > ng-form div:first-child {
    padding-top: 17px;
}

.interview-field-wrapper > div:last-child label {
    font-weight: normal;
}

@media(min-width: 992px) {
    .interview-field-wrapper > div:first-child,
    .interview-field-wrapper > div:last-child {
        padding-top: 36px;
    }
}

.mark-progress {
    margin: 12px 0 0;
}

.applicants_edit .tab-content{
    min-height: 300px;
}
.timeline>li{
    margin-bottom: 5px;
}
.timeline>li>.timeline-item>.timeline-header{
    padding: 0;
}
.timeline>li>.timeline-item>.time {
    padding: 0;
}
.timeline>li>.fa, .timeline>li>.glyphicon, .timeline>li>.ion{
    width: 22px;
    height: 22px;
    font-size: 11px;
    line-height: 23px;
    left: 23px;
}
.author{
     margin-right: 10px;
}
.calendar_buttons{
    background-color: transparent;
    border-radius: 0;
    margin-right: -2px!important;
}
.delete_button{
    margin-top: 2px;
}
.ui-select-multiple.ui-select-bootstrap{
    padding-right: 0;
}
.ui-select-multiple.ui-select-bootstrap input.ui-select-search{
    padding-right: 20px;
}
.tags {
  display: inline-block;
  position: absolute;
  z-index: 1000;
  width: 0;
  height: 0;
  top: 45%;
  left: auto;
  right: 7px;
  vertical-align: middle;
  border-top: 6px solid #000000;
  border-right: 3px solid transparent;
  border-bottom: 0 dotted;
  border-left: 3px solid transparent;
  /*content: "";*/
}
.for_create {
    min-height: 20px!important;
}
.applicants_create .radio {
    display: inline-block;
    margin-right: 10px;
}
.no-margin {
    margin: 0px;
}
.plus{
    color: #456F38;
    margin: 0;
}
.minus {
    color: #660000;
    margin: 0;
}
.ui-select-bootstrap .ui-select-choices-row.active>a {
    cursor: pointer;
}
.even{
  background-color: #ECF0F1;
}
.interview_tab {
    margin-left: -25px;
    margin-right: -25px
}
.interview_list {
  padding-right: 15px;
  padding-left: 15px;
}

.applicants_create .controls,
.applicants_edit .controls {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 1020;
}

.applicants_create .controls .content,
.applicants_edit .controls .content {
  margin: 0;
  min-height: 0;
  padding: 10px 15px;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
}

.applicants_create .controls .radio {
  margin: 7px 15px 7px 0;
}

.block_photo div {
  text-align: center;
}

.block_photo span {
  position: relative;
  display: inline-block;
  height: 344px;
}

.block_photo .btn {
  position: absolute;
  right: 15px;
  opacity: 0.2;
}

.block_photo span:hover .btn {
  opacity: 1;
}

.block_photo .btn.remove {
  top: 15px;
}

.block_photo .btn.upload {
  bottom: 15px;
}
.note{
  min-height: 34px;
}
.applicants_create .photo,
.applicants_edit .photo{
    display: inline-block;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    width: 100%;

}
.photo_wrapper {
    min-width: 282px;
}
.modal textarea.form-control.msd-elastic {
  min-height: 34px;
}

.icons{
  opacity: 0.7;
  width: 15px;
  height: 15px;
}
.icons:hover{
  opacity: 0.5;
}

.nav-tabs-custom>.nav-tabs>li.active .icons{
  opacity: 0.7;
}

.search-channel {
  float: right;
}