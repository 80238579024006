.word-breaker {
    word-break: break-all;
}

.table-bordered > tbody > tr.border-bottom > td,
.border-bottom {
    border-bottom: 1px solid #000;
}

.table-bordered > tbody > tr.border-top > td,
.border-top {
    border-top: 1px solid #000;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #999;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #999;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #999;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #999;
}
:placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
  color:    #999;
}
select.placeholder {
  color: #999;
  padding-left: 10px;
}
select.placeholder option {
  color: #333;
}


.no-js body>.ui-view {
    display: none;
}




.huge-alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    text-transform: uppercase;
    z-index:1500;
    background-color: #f25648;
    color: #ffffff;
}

.huge-alert h6 {
    font-size: 25px;
    line-height: 25px;
    margin: 30px 0 10px;
}

.huge-alert p {
    font-size: 12px;
    line-height: 12px;
    margin: 0;
}

.huge-alert .btn-action {
    display: block;
    position: relative;
    padding: 10px 20px;
    margin: 30px auto 0;
    width: 230px; /*need for IE*/
    color: #ffffff;
    text-decoration: none;
    border: 2px solid #ffffff;
    cursor: pointer;
}

.huge-alert .btn-action:hover {
    color: #f25648;
    background-color:#ffffff;
}

.huge-alert .btn-close {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #ffffff;
    font-size: 36px;
    line-height: 36px;
}

.cursor-pointer {
    cursor: pointer;
}