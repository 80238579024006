@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    src: url('../../bower_components/source-sans-pro/EOT/SourceSans3-ExtraLight.eot') format('embedded-opentype'),
         url('../../bower_components/source-sans-pro/WOFF2/TTF/SourceSans3-ExtraLight.ttf.woff2') format('woff2'),
         url('../../bower_components/source-sans-pro/WOFF/OTF/SourceSans3-ExtraLight.otf.woff') format('woff'),
         url('../../bower_components/source-sans-pro/OTF/SourceSans3-ExtraLight.otf') format('opentype'),
         url('../../bower_components/source-sans-pro/TTF/SourceSans3-ExtraLight.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 200;
    font-style: italic;
    font-stretch: normal;
    src: url('../../bower_components/source-sans-pro/EOT/SourceSans3-ExtraLightIt.eot') format('embedded-opentype'),
         url('../../bower_components/source-sans-pro/WOFF2/TTF/SourceSans3-ExtraLightIt.ttf.woff2') format('woff2'),
         url('../../bower_components/source-sans-pro/WOFF/OTF/SourceSans3-ExtraLightIt.otf.woff') format('woff'),
         url('../../bower_components/source-sans-pro/OTF/SourceSans3-ExtraLightIt.otf') format('opentype'),
         url('../../bower_components/source-sans-pro/TTF/SourceSans3-ExtraLightIt.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    src: url('../../bower_components/source-sans-pro/EOT/SourceSans3-Light.eot') format('embedded-opentype'),
         url('../../bower_components/source-sans-pro/WOFF2/TTF/SourceSans3-Light.ttf.woff2') format('woff2'),
         url('../../bower_components/source-sans-pro/WOFF/OTF/SourceSans3-Light.otf.woff') format('woff'),
         url('../../bower_components/source-sans-pro/OTF/SourceSans3-Light.otf') format('opentype'),
         url('../../bower_components/source-sans-pro/TTF/SourceSans3-Light.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    src: url('../../bower_components/source-sans-pro/EOT/SourceSans3-LightIt.eot') format('embedded-opentype'),
         url('../../bower_components/source-sans-pro/WOFF2/TTF/SourceSans3-LightIt.ttf.woff2') format('woff2'),
         url('../../bower_components/source-sans-pro/WOFF/OTF/SourceSans3-LightIt.otf.woff') format('woff'),
         url('../../bower_components/source-sans-pro/OTF/SourceSans3-LightIt.otf') format('opentype'),
         url('../../bower_components/source-sans-pro/TTF/SourceSans3-LightIt.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    src: url('../../bower_components/source-sans-pro/EOT/SourceSans3-Regular.eot') format('embedded-opentype'),
         url('../../bower_components/source-sans-pro/WOFF2/TTF/SourceSans3-Regular.ttf.woff2') format('woff2'),
         url('../../bower_components/source-sans-pro/WOFF/OTF/SourceSans3-Regular.otf.woff') format('woff'),
         url('../../bower_components/source-sans-pro/OTF/SourceSans3-Regular.otf') format('opentype'),
         url('../../bower_components/source-sans-pro/TTF/SourceSans3-Regular.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    src: url('../../bower_components/source-sans-pro/EOT/SourceSans3-It.eot') format('embedded-opentype'),
         url('../../bower_components/source-sans-pro/WOFF2/TTF/SourceSans3-It.ttf.woff2') format('woff2'),
         url('../../bower_components/source-sans-pro/WOFF/OTF/SourceSans3-It.otf.woff') format('woff'),
         url('../../bower_components/source-sans-pro/OTF/SourceSans3-It.otf') format('opentype'),
         url('../../bower_components/source-sans-pro/TTF/SourceSans3-It.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    src: url('../../bower_components/source-sans-pro/EOT/SourceSans3-Semibold.eot') format('embedded-opentype'),
         url('../../bower_components/source-sans-pro/WOFF2/TTF/SourceSans3-Semibold.ttf.woff2') format('woff2'),
         url('../../bower_components/source-sans-pro/WOFF/OTF/SourceSans3-Semibold.otf.woff') format('woff'),
         url('../../bower_components/source-sans-pro/OTF/SourceSans3-Semibold.otf') format('opentype'),
         url('../../bower_components/source-sans-pro/TTF/SourceSans3-Semibold.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-style: italic;
    font-stretch: normal;
    src: url('../../bower_components/source-sans-pro/EOT/SourceSans3-SemiboldIt.eot') format('embedded-opentype'),
         url('../../bower_components/source-sans-pro/WOFF2/TTF/SourceSans3-SemiboldIt.ttf.woff2') format('woff2'),
         url('../../bower_components/source-sans-pro/WOFF/OTF/SourceSans3-SemiboldIt.otf.woff') format('woff'),
         url('../../bower_components/source-sans-pro/OTF/SourceSans3-SemiboldIt.otf') format('opentype'),
         url('../../bower_components/source-sans-pro/TTF/SourceSans3-SemiboldIt.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    src: url('../../bower_components/source-sans-pro/EOT/SourceSans3-Bold.eot') format('embedded-opentype'),
         url('../../bower_components/source-sans-pro/WOFF2/TTF/SourceSans3-Bold.ttf.woff2') format('woff2'),
         url('../../bower_components/source-sans-pro/WOFF/OTF/SourceSans3-Bold.otf.woff') format('woff'),
         url('../../bower_components/source-sans-pro/OTF/SourceSans3-Bold.otf') format('opentype'),
         url('../../bower_components/source-sans-pro/TTF/SourceSans3-Bold.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
    src: url('../../bower_components/source-sans-pro/EOT/SourceSans3-BoldIt.eot') format('embedded-opentype'),
         url('../../bower_components/source-sans-pro/WOFF2/TTF/SourceSans3-BoldIt.ttf.woff2') format('woff2'),
         url('../../bower_components/source-sans-pro/WOFF/OTF/SourceSans3-BoldIt.otf.woff') format('woff'),
         url('../../bower_components/source-sans-pro/OTF/SourceSans3-BoldIt.otf') format('opentype'),
         url('../../bower_components/source-sans-pro/TTF/SourceSans3-BoldIt.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    src: url('../../bower_components/source-sans-pro/EOT/SourceSans3-Black.eot') format('embedded-opentype'),
         url('../../bower_components/source-sans-pro/WOFF2/TTF/SourceSans3-Black.ttf.woff2') format('woff2'),
         url('../../bower_components/source-sans-pro/WOFF/OTF/SourceSans3-Black.otf.woff') format('woff'),
         url('../../bower_components/source-sans-pro/OTF/SourceSans3-Black.otf') format('opentype'),
         url('../../bower_components/source-sans-pro/TTF/SourceSans3-Black.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 900;
    font-style: italic;
    font-stretch: normal;
    src: url('../../bower_components/source-sans-pro/EOT/SourceSans3-BlackIt.eot') format('embedded-opentype'),
         url('../../bower_components/source-sans-pro/WOFF2/TTF/SourceSans3-BlackIt.ttf.woff2') format('woff2'),
         url('../../bower_components/source-sans-pro/WOFF/OTF/SourceSans3-BlackIt.otf.woff') format('woff'),
         url('../../bower_components/source-sans-pro/OTF/SourceSans3-BlackIt.otf') format('opentype'),
         url('../../bower_components/source-sans-pro/TTF/SourceSans3-BlackIt.ttf') format('truetype');
}
