.calendar_day .content,
.calendar_week .content {
  /*background-color: #ffffff;*/
  min-height: 900px;
}
.calendar_day a:hover,
.calendar_week a:hover{
  color: #005c90;
}
.table {
  margin-bottom: 0px;
}

.table > tbody > tr > td {
  padding-top: 0px;
  padding-bottom: 0px;
}

.calendar_datepicker {
  display:inline-block;
  min-height: 265px;
}

.table-striped > thead > tr > th {
  border-bottom:1px solid #000000;
}

.choice_date {
  font-size: 2.5em;
}

.content-header {
  padding-left: 2px;
}

.buttons_size {
  width: 138px;
  float: left;
  border-radius: 0px;
}

.buttons_group {
  position: absolute;
  clip: rect(0,0,0,0);
  pointer-events: none;
}

.clock {
  width: 200px;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .date_time {
    display: inline-block;
  }
}

.loader {
  min-height: 500px;
}

.calendar_day .box,
.calendar_week .box {
  border: none;
}

.calendar_datepicker .btn.active {
  box-shadow: none;
  outline: none;
}

.calendar_datepicker .btn-default.active {
  background-color: #f4f4f4;
  border-color: #ddd;
}

.calendar_datepicker .current {
  border-color: #444;
}

.calendar_datepicker span.text-info {
  color: #444;
}

.calendar_datepicker .btn-info.active span,
.calendar_datepicker .btn-info:focus span,
.calendar_datepicker .week-active .btn span {
  color: #fff;
}

.calendar_datepicker .btn-info.active,
.calendar_datepicker .week-active .btn,
.calendar_datepicker .week-active .btn:focus,
.calendar_datepicker .week-active .btn-info.active {
  background-color: #3c8dbc;
  border-color: #357ca5;
}
